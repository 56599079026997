<template>
	<b-row>
		<b-col cols="12">
			<b-card class="py-5 px-5" no-body style="height: calc(100vh - 15rem)">
				<div class="position-relative h-100" style="background: #1dc6f2"></div>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
	export default {
		name: "index",
	};
</script>

<style lang="scss" scoped></style>
